import { RequisitesTransaction, ValueTypes } from '@dltru/dfa-models'
import clsx from 'clsx'
import { FC } from 'react'

import { ExportSmall, PapperclipSmall } from '../../../Icons'
import { CopyButton } from '../../../button'
import style from './style.m.less'

const TypedValue: FC<RequisitesTransaction> = ({ type, label, value, to, symbol, onDownload }) => {
    let valueEL = (
        <div className={style.reqRowValue}>
            <div className={clsx(style.reqRowValueLabel, style[`reqRowValueLabel${type}`])}>
                {value}
            </div>
        </div>
    )

    if (type === ValueTypes.copied) {
        valueEL = (
            <div className={style.reqRowValue}>
                <div className={clsx(style.reqRowValueLabel, style[`reqRowValueLabel${type}`])}>
                    {value}
                </div>
                <div className={style.reqRowValueIcon}>
                    <CopyButton text={value} />
                </div>
            </div>
        )
    }

    if (type === ValueTypes.linked) {
        valueEL = (
            <a className={style.reqRowValue} href={to}>
                <div className={clsx(style.reqRowValueLabel, style[`reqRowValueLabel${type}`])}>
                    {value}
                </div>
                <div className={style.reqRowValueIcon}>
                    <ExportSmall />
                </div>
            </a>
        )
    }

    if (type === ValueTypes.externalLink) {
        valueEL = (
            <a className={style.reqRowValue} href={to} target="_blank" rel="noreferrer">
                <div className={style.reqRowValueIcon}>
                    <PapperclipSmall />
                </div>
                <div className={clsx(style.reqRowValueLabel, style[`reqRowValueLabel${type}`])}>
                    {value}
                </div>
            </a>
        )
    }

    if (type === ValueTypes.fiatValue) {
        const [integer, cents] = value.split(',')
        valueEL = (
            <a className={style.reqRowValue} href={to}>
                <div className={clsx(style.reqRowValueLabel, style[`reqRowValueLabel${type}`])}>
                    {integer}
                </div>
                <div
                    className={clsx(style.reqRowValueLabel, style.fiatValueShadowed)}
                >{`,${cents} ${symbol}`}</div>
            </a>
        )
    }

    if (type === ValueTypes.attachment) {
        valueEL = (
            <a className={style.reqRowValue} href={to} target="_blank" rel="noreferrer">
                <div className={style.reqRowValueIcon}>
                    <PapperclipSmall />
                </div>
                <div
                    className={clsx(style.reqRowValueLabel, style[`reqRowValueLabel${type}`])}
                    onClick={() => {
                        onDownload && onDownload()
                    }}
                >
                    {value}
                </div>
            </a>
        )
    }

    return (
        <div className={style.reqRow}>
            <div className={style.reqRowLabel}>{label}</div>
            {valueEL}
        </div>
    )
}

export default TypedValue
